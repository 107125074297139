
export default {
  data() {
    return {
      form : {
        id : '',
        nik : '',
        nama_lengkap : '',
        tmp_lahir : '',
        tgl_lahir : '',
        jns_kelamin : '',
        alamat : '',
        dusun : '',
        rt_rw : '',
        kecamatan_id : '',
        des_kel_id : '',
        telp_hp : '',
        email : '',
        kode_pos : '',
        status_perkawinan_id : '',
        uraian_perkawinan : '',
        agama_id : '',
        uraian_agama : '',
        tinggi_bdn : '',
        berat_bdn : '',
        file : null,

        username : '',
        nama : '',
      },



      formPendidikan : {
        id : '',
        biodata_id : '',
        nama_pendidikan : '',
        pendidikan_id : '',
        tahun_tamat : '',
        nem_ipk : '',
        jurusan : '',
        jurusan_id : '',
        pendidikan_formal : '',
        tipe_nilai : '',
        nilai_rata_ijazah : '',
        keterampilan_id : [],
        tahun_keterampilan : '',
      },




      formPengalamanKerja : {
        id : '',
        biodata_id : '',
        jabatan : '',
        uraian_tugas : '',
        lama_kerja : '',
        pemberian_pengguna : '',
        catatan : '',
      },



      tmp_lahir : '',

      list_data : [],
      list_pendidikan : [],
      list_pengalaman_kerja : [],
      list_mitra : [],
      list_loker : [],
      page_first: 1,
      page_last: 0,
      page_limit: 10,
      cari_value : "",
      file_old : "",
      cek_load_data : true,
      file_path: this.$store.state.url.URL_APP + "uploads/",

      countPendaftar: '',
      countDiterima: '',
      countDitolak: '',
      countDiproses: '',
      alert: false,
      simpan1: false,
      model :'',
      inputSelect : [
        {id : '1', nama : 'kiken', alamat : 'beringin'},
        {id : '2',nama : 'Alung', alamat : 'Anawai'},
      ],
      date : '',

      nama : '',
      nip : '',
      username : '',


      pilih_kecamatan : '',
      kecamatan : [],
    }
  },
  methods: {
    // getView : function(){
    //   this.$store.commit("shoWLoading");
    //   fetch(this.$store.state.url.URL_DASHBOARD + "view", {
    //       method: "POST",
    //       headers: {
    //       "content-type": "application/json",
    //       authorization: "kikensbatara " + localStorage.token
    //       },
    //       body: JSON.stringify({
    //           data_ke: this.page_first,
    //           cari_value: this.cari_value,
    //       })
    //   })
    //       .then(res => res.json())
    //       .then(res_data => {
    //           this.list_data = res_data.data;
    //           this.page_last = res_data.jml_data;
    //           this.$store.commit("hideLoading");
    //         // this.list_data = res_data[0];
    //         // var data = res_data[0];
    //         // this.form.id = data.id;
    //         // this.form.tmp_lahir = data.tmp_lahir;
    //         // this.form.tgl_lahir = data.tgl_lahir;
    //         // this.form.alamat = data.alamat;
    //         // this.form.jns_kelamin = data.jns_kelamin;
    //         // this.form.status_perkawinan_id = data.status_perkawinan_id;
    //         // this.form.besaran_upah_id = data.besaran_upah_id;
    //         // this.form.uraian_besaran_upah = data.uraian_besaran_upah;  
    //         // console.log(res_data);
    //   });
    // },

    // getViewPendidikan : function(){
    //   this.$store.commit("shoWLoading");
    //   fetch(this.$store.state.url.URL_DASHBOARD + "viewPendidikan", {
    //       method: "POST",
    //       headers: {
    //       "content-type": "application/json",
    //       authorization: "kikensbatara " + localStorage.token
    //       },
    //       body: JSON.stringify({
    //           data_ke: this.page_first,
    //           cari_value: this.cari_value,
    //       })
    //   })
    //       .then(res => res.json())
    //       .then(res_data => {
    //           this.list_pendidikan = res_data.data;
    //           this.page_last = res_data.jml_data;
    //           this.$store.commit("hideLoading");
    //         // this.list_data = res_data[0];
    //         // var data = res_data[0];
    //         // this.form.id = data.id;
    //         // this.form.tmp_lahir = data.tmp_lahir;
    //         // this.form.tgl_lahir = data.tgl_lahir;
    //         // this.form.alamat = data.alamat;
    //         // this.form.jns_kelamin = data.jns_kelamin;
    //         // this.form.status_perkawinan_id = data.status_perkawinan_id;
    //         // this.form.besaran_upah_id = data.besaran_upah_id;
    //         // this.form.uraian_besaran_upah = data.uraian_besaran_upah;  
    //         // console.log(res_data);
    //   });
    // },

    // getViewPengalamanKerja : function(){
    //   this.$store.commit("shoWLoading");
    //   fetch(this.$store.state.url.URL_DASHBOARD + "viewPengalamanKerja", {
    //       method: "POST",
    //       headers: {
    //       "content-type": "application/json",
    //       authorization: "kikensbatara " + localStorage.token
    //       },
    //       body: JSON.stringify({
    //           data_ke: this.page_first,
    //           cari_value: this.cari_value,
    //       })
    //   })
    //       .then(res => res.json())
    //       .then(res_data => {
    //           this.list_pengalaman_kerja = res_data.data;
    //           this.page_last = res_data.jml_data;
    //           this.$store.commit("hideLoading");
    //         // this.list_data = res_data[0];
    //         // var data = res_data[0];
    //         // this.form.id = data.id;
    //         // this.form.tmp_lahir = data.tmp_lahir;
    //         // this.form.tgl_lahir = data.tgl_lahir;
    //         // this.form.alamat = data.alamat;
    //         // this.form.jns_kelamin = data.jns_kelamin;
    //         // this.form.status_perkawinan_id = data.status_perkawinan_id;
    //         // this.form.besaran_upah_id = data.besaran_upah_id;
    //         // this.form.uraian_besaran_upah = data.uraian_besaran_upah;  
    //         // console.log(res_data);
    //   });
    // },

    jmlPendaftar : function() { 
      fetch(this.$store.state.url.URL_DASHBOARD + "jmlPendaftar", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          }, 
      })
      .then(res => res.json())
      .then(res_data => {
        // this.list_data  = res_data.data;
        this.countPendaftar = res_data.total
        // console.log(res_data.total);
      }); 
    }, 

    jmlDiterima : function() { 
      fetch(this.$store.state.url.URL_DASHBOARD + "jmlDiterima", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          }, 
      })
      .then(res => res.json())
      .then(res_data => {
        // this.list_data  = res_data.data;  
        this.countDiterima = res_data.total
        // console.log(res_data.total);
      }); 
    }, 

    jmlDitolak : function() { 
      fetch(this.$store.state.url.URL_DASHBOARD + "jmlDitolak", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          }, 
      })
      .then(res => res.json())
      .then(res_data => {
        this.countDitolak = res_data.total
      }); 
    }, 

    jmlDiproses : function() { 
      fetch(this.$store.state.url.URL_DASHBOARD + "jmlDiproses", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          }, 
      })
      .then(res => res.json())
      .then(res_data => {
        this.countDiproses = res_data.total
      }); 
    }, 

    getMitra : function (){
      fetch(this.$store.state.url.URL_DASHBOARD + "viewMitra", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              // data_ke: this.page_first,
              // cari_value: this.cari_value,
              // page_limit: this.page_limit,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_mitra = res_data.data;
              // this.page_last = res_data.jml_data;
              // this.$store.commit("hideLoading");
              // console.log(res_data);
      });
    },

    getLoker : function (){
      fetch(this.$store.state.url.URL_DASHBOARD + "viewLoker", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              // data_ke: this.page_first,
              // cari_value: this.cari_value,
              // page_limit: this.page_limit,
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_loker = res_data.data;
              // this.page_last = res_data.jml_data;
              // this.$store.commit("hideLoading");
              // console.log(res_data);
      });
    },

    simulateProgress (number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    },

    getKecamatan: function() {
      fetch(this.$store.state.url.URL_DASHBOARD + "viewKecamatan", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
            kecamatan : this.pilih_kecamatan,
          })
      }).then(res => res.json())
        .then(res_data => {
          this.kelompok_kecamatan = res_data.data;

          var kelompok_kecamatan = [];
          var hasil = this.kelompok_kecamatan;
          for(var i in hasil) {
            kelompok_kecamatan.push([
              hasil[i].nama_kecamatan,
              hasil[i].jml
            ])
          }
          console.log(res_data);

          this.grafikKecamatan(kelompok_kecamatan);
      });
    },

    grafikKecamatan(kelompok_kecamatan){
      Highcharts.chart(container, {
          chart: {
            borderColor: '#transparent',
            borderWidth: 2,
            backgroundColor: 'transparent'
          },
          title: {
            text: 'Permohonan Pencetakan Kartu Kuning'
          },
          subtitle: {
            text: 'Berdasarkan Kecamatan'
          },
          xAxis: {
            type: 'category'
          },
          series: [{
            type: 'column',
            data: kelompok_kecamatan,
            showInLegend: false
          }]
      });
    },

    KehadiranPie : function(){
      // Highcharts.chart('KehadiranPie', {
      // chart: {
      //     type: 'pie',
      //     backgroundColor: 'transparent'
      // },
      // title: {
      //     text: 'DATA PRESENSI KEHADIRAN'
      // },
      // tooltip: {
      //     pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      // },
      // accessibility: {
      //     point: {
      //         valueSuffix: '%'
      //     }
      // },
      // plotOptions: {
      // pie: {
      //     allowPointSelect: true,
      //     cursor: 'pointer',
      //     dataLabels: {
      //         enabled: false
      //     },
      //     showInLegend: true
      // }
      // },
      // series: [{
      //     name: 'Persentase',
      //     colorByPoint: true,
      //     data: [
      //       {
      //         name: 'Hadir',
      //         y: 52.08,
      //         color:'#1e88e5',
      //         // sliced: true,
      //         // selected: true
      //       }, 
      //       {
      //         name: 'Izin',
      //         y: 22.92,
      //         color:'#7cb342',
      //       },
      //       {
      //         name: 'Tanpa Keterangan',
      //         y: 25.00,
      //         color:'#ff8a65',
      //       },
          
      //     ]
      //   }]
      // });



    },

    // KehadiranApelPie : function(){

    //   // Create the chart
    //   Highcharts.chart('container', {
    //       chart: {
    //           type: 'column',
    //           backgroundColor: 'transparent'
    //       },
    //       title: {
    //           text: 'Browser market shares. January, 2022'
    //       },
    //       accessibility: {
    //           announceNewData: {
    //               enabled: true
    //           }
    //       },
    //       xAxis: {
    //           type: 'category'
    //       },
    //       yAxis: {
    //           title: {
    //               text: 'Total'
    //           }

    //       },
    //       legend: {
    //           enabled: false
    //       },
    //       plotOptions: {
    //           series: {
    //               borderWidth: 0,
    //               dataLabels: {
    //                   enabled: true,
    //                   format: '{point.y:.1f}%'
    //               }
    //           }
    //       },

    //       tooltip: {
    //           headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
    //           pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
    //       },

    //       series: [
    //           {
    //               name: 'Browsers',
    //               colorByPoint: true,
    //               data: [
    //                   {
    //                       name: 'Chrome',
    //                       y: 63.06,
    //                       drilldown: 'Chrome'
    //                   },
    //                   {
    //                       name: 'Safari',
    //                       y: 19.84,
    //                       drilldown: 'Safari'
    //                   },
    //                   {
    //                       name: 'Firefox',
    //                       y: 4.18,
    //                       drilldown: 'Firefox'
    //                   },
    //                   {
    //                       name: 'Edge',
    //                       y: 4.12,
    //                       drilldown: 'Edge'
    //                   },
    //                   {
    //                       name: 'Opera',
    //                       y: 2.33,
    //                       drilldown: 'Opera'
    //                   },
    //                   {
    //                       name: 'Internet Explorer',
    //                       y: 0.45,
    //                       drilldown: 'Internet Explorer'
    //                   },
    //                   {
    //                       name: 'Other',
    //                       y: 1.582,
    //                       drilldown: null
    //                   }
    //               ]
    //           }
    //       ],
    //       drilldown: {
    //           breadcrumbs: {
    //               position: {
    //                   align: 'right'
    //               }
    //           },
    //           series: [
    //               {
    //                   name: 'Chrome',
    //                   id: 'Chrome',
    //                   data: [
    //                       [
    //                           'v65.0',
    //                           0.1
    //                       ],
    //                       [
    //                           'v64.0',
    //                           1.3
    //                       ],
    //                       [
    //                           'v63.0',
    //                           53.02
    //                       ],
    //                       [
    //                           'v62.0',
    //                           1.4
    //                       ],
    //                       [
    //                           'v61.0',
    //                           0.88
    //                       ],
    //                       [
    //                           'v60.0',
    //                           0.56
    //                       ],
    //                       [
    //                           'v59.0',
    //                           0.45
    //                       ],
    //                       [
    //                           'v58.0',
    //                           0.49
    //                       ],
    //                       [
    //                           'v57.0',
    //                           0.32
    //                       ],
    //                       [
    //                           'v56.0',
    //                           0.29
    //                       ],
    //                       [
    //                           'v55.0',
    //                           0.79
    //                       ],
    //                       [
    //                           'v54.0',
    //                           0.18
    //                       ],
    //                       [
    //                           'v51.0',
    //                           0.13
    //                       ],
    //                       [
    //                           'v49.0',
    //                           2.16
    //                       ],
    //                       [
    //                           'v48.0',
    //                           0.13
    //                       ],
    //                       [
    //                           'v47.0',
    //                           0.11
    //                       ],
    //                       [
    //                           'v43.0',
    //                           0.17
    //                       ],
    //                       [
    //                           'v29.0',
    //                           0.26
    //                       ]
    //                   ]
    //               },
    //               {
    //                   name: 'Firefox',
    //                   id: 'Firefox',
    //                   data: [
    //                       [
    //                           'v58.0',
    //                           1.02
    //                       ],
    //                       [
    //                           'v57.0',
    //                           7.36
    //                       ],
    //                       [
    //                           'v56.0',
    //                           0.35
    //                       ],
    //                       [
    //                           'v55.0',
    //                           0.11
    //                       ],
    //                       [
    //                           'v54.0',
    //                           0.1
    //                       ],
    //                       [
    //                           'v52.0',
    //                           0.95
    //                       ],
    //                       [
    //                           'v51.0',
    //                           0.15
    //                       ],
    //                       [
    //                           'v50.0',
    //                           0.1
    //                       ],
    //                       [
    //                           'v48.0',
    //                           0.31
    //                       ],
    //                       [
    //                           'v47.0',
    //                           0.12
    //                       ]
    //                   ]
    //               },
    //               {
    //                   name: 'Internet Explorer',
    //                   id: 'Internet Explorer',
    //                   data: [
    //                       [
    //                           'v11.0',
    //                           6.2
    //                       ],
    //                       [
    //                           'v10.0',
    //                           0.29
    //                       ],
    //                       [
    //                           'v9.0',
    //                           0.27
    //                       ],
    //                       [
    //                           'v8.0',
    //                           0.47
    //                       ]
    //                   ]
    //               },
    //               {
    //                   name: 'Safari',
    //                   id: 'Safari',
    //                   data: [
    //                       [
    //                           'v11.0',
    //                           3.39
    //                       ],
    //                       [
    //                           'v10.1',
    //                           0.96
    //                       ],
    //                       [
    //                           'v10.0',
    //                           0.36
    //                       ],
    //                       [
    //                           'v9.1',
    //                           0.54
    //                       ],
    //                       [
    //                           'v9.0',
    //                           0.13
    //                       ],
    //                       [
    //                           'v5.1',
    //                           0.2
    //                       ]
    //                   ]
    //               },
    //               {
    //                   name: 'Edge',
    //                   id: 'Edge',
    //                   data: [
    //                       [
    //                           'v16',
    //                           2.6
    //                       ],
    //                       [
    //                           'v15',
    //                           0.92
    //                       ],
    //                       [
    //                           'v14',
    //                           0.4
    //                       ],
    //                       [
    //                           'v13',
    //                           0.1
    //                       ]
    //                   ]
    //               },
    //               {
    //                   name: 'Opera',
    //                   id: 'Opera',
    //                   data: [
    //                       [
    //                           'v50.0',
    //                           0.96
    //                       ],
    //                       [
    //                           'v49.0',
    //                           0.82
    //                       ],
    //                       [
    //                           'v12.1',
    //                           0.14
    //                       ]
    //                   ]
    //               }
    //           ]
    //       }
    //   });




    // },



    

    async button(){
      await setTimeout(() => {
        // console.log('ini query pembacaannya')
      }, 2000);
      // console.log('telASO')
    },


    getTahun: function(){
      this.jmlPendaftar();
      this.jmlDiterima();
      this.jmlDitolak();
      this.jmlDiproses();
      this.getLoker();
    },





    indexing : function(index){
      var idx = ((this.page_first-1)*this.page_limit)+index
      return idx
    },

  },

  mounted () {
    // this.KehadiranPie();
    this.grafikKecamatan();
    var get_profile = JSON.parse(localStorage.profile);
    this.nama = get_profile.profile.nama;
    this.username = get_profile.profile.username;
    this.email = get_profile.profile.email;
    this.button();
    this.jmlPendaftar();
    this.jmlDiterima();
    this.jmlDitolak();
    this.jmlDiproses();


    // this.getView();
    // this.getViewPendidikan();
    // this.getViewPengalamanKerja();
    this.getKecamatan();
    this.getMitra();
    this.getLoker();
  },
}
