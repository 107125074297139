import Vue from 'vue'
import Vuex from 'vuex'

import { Loading,  QSpinnerFacebook,  } from 'quasar'
import { Notify } from 'quasar'

Vue.use(Vuex)

// var URL = 'http://localhost:5043/'
// var URL = 'http://36.88.33.75:5043/'
// var URL = 'http://server.perak.konaweselatankab.go.id/'
var URL = 'https://server.perak.konaweselatankab.go.id/'

export default new Vuex.Store({
  state: {
    coordinat : {
      lat:-4.034694, 
      lng: 122.484263
    },
    btn : {
      add : true,
      edit : true,
      remove : true,
    },
    url : {
      URL_APP : URL,
      checkAuth : URL + 'api/v1/checkAuth/',
      URL_DM_REGISTER : URL+'api/v1/dm_registrasi/',
      URL_DM_MENU : URL+'api/v1/dm_menuList/',
      URL_DM_KLP_USERS : URL+'api/v1/dm_kelompokUsers/',
      URL_DM_STATUS_PERKAWINAN : URL+'api/v1/dm_perkawinan/',
      URL_DM_AGAMA : URL+'api/v1/dm_agama/',
      URL_DM_BAHASA_ASING : URL+'api/v1/dm_bahasa_asing/',
      URL_DM_BESARAN_UPAH : URL+'api/v1/dm_master_besaran_upah/',
      URL_DM_PENDIDIKAN : URL+'api/v1/dm_master_pendidikan/',
      URL_DM_JURUSAN : URL+'api/v1/dm_master_jurusan/',
      URL_DM_KETERAMPILAN : URL+'api/v1/dm_master_keterampilan/',
      URL_DM_BIDANG : URL+'api/v1/master_bidang/',
      URL_DM_KECAMATAN : URL + 'api/v1/m_kecamatan/',
      URL_DM_DES_KEL : URL + 'api/v1/m_des_kel/',
      URL_BIO_KETERANGAN_UMUM : URL + 'api/v1/keterangan_umum/',
      URL_BIO_PENDIDIKAN_FORMAL : URL + 'api/v1/pendidikan_formal/',
      URL_BIO_PENGALAMAN_KERJA : URL + 'api/v1/pengalaman_kerja/',
      URL_BIO_BAHASA_ASING : URL + 'api/v1/bahasa_asing/',
      URL_BIO_KETERAMPILAN : URL + 'api/v1/keterampilan/',
      URL_BIO_JABATAN : URL + 'api/v1/jabatan/',
      URL_BIO_LAMPIRAN : URL + 'api/v1/lampiran_biodata/',
      URL_VERIFIKASI : URL + 'api/v1/verifikasi/',
      URL_LAPORAN : URL + 'api/v1/laporan/',
      URL_CETAK_KARTU : URL + 'api/v1/cetak_kartu/',
      URL_DASHBOARD : URL + 'api/v1/dashboard/',
      URL_PROFIL : URL + 'api/v1/profil/',
      URL_LOKER : URL + 'api/v1/loker/',
      URL_TRACKING_PENDAFTAR : URL + 'api/v1/tracking_pendaftar/',
      URL_WA : URL + 'api/v1/routerWa/',
      URL_BERITA : URL + 'api/v1/berita/',
      URL_UNIT_KERJA : URL + 'api/v1/unit_kerja/',
    },

    list_contoh_autocomplete : [],
    list_status_perkawinan : [],
    list_agama : [],
    list_bahasa : [],
    list_kecamatan : [],
    list_des_kel : [],
    list_upah : [],
    list_pendidikan : [],
    list_jurusan : [],
    list_keterampilan : [],
    list_bidang : [],
    
    list_menu : null,
    aksesMenu : {},

    page_first: 1,
    page_last: 0,
    cari_value: "",
    cek_load_data : true,

    type : [
      {id : 0, uraian : 'Single Menu'},
      {id : 1, uraian : 'Multy Menu'}
    ],

    tahun : [
      {id: 2023, uraian: '2023'},
      {id: 2024, uraian: '2024'},
      {id: 2025, uraian: '2025'},
      {id: 2026, uraian: '2026'}
    ],

    
  },
  mutations: {
    listJeniskategorilokasi(state){

      fetch(state.url.URL_MasterKategoriLokasi, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        }
      })
        .then(res => res.json())
        .then(res_data => {
          state.list_MasterKategoriLokasi = res_data;
      });

    },

    listApelJenis(state){

      fetch(state.url.URL_apelJenis, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
        }
      })
        .then(res => res.json())
        .then(res_data => {
          // console.log(res_data)
          state.list_ApleJenis = res_data;
      });

    },

    getStorage(state){
      var get_profile = JSON.parse(localStorage.profile);
      state.unit_kerja = get_profile.profile.unit_kerja; 
    },
    shoWLoading(){
      const spinner = typeof QSpinnerFacebook !== 'undefined'
        ? QSpinnerFacebook // Non-UMD, imported above
        : Quasar.components.QSpinnerFacebook // eslint-disable-line


      Loading.show({
        spinner,
        spinnerColor: 'yellow',
        spinnerSize: 140,
        backgroundColor: 'purple',
        // message: 'Loading... Tunggu beberapa saat, system sedang menyesuaikan akun anda..!',
        // messageColor: 'white'
      })
    },
    hideLoading(){
      Loading.hide()
    },
    shoWNotify(state, message, color, icon){
      Notify.create({
        message: message,
        color: color,
        position : 'top-right',
        icon:icon
      })
    },
  },
  actions: {
  },
  modules: {
  }
})
