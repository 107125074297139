import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);





function loggedInRedirectDashboard(to, from, next) {
  if (localStorage.token) {
    next('/');
  } else {
    next();
  }
}

function isLoggedIn(to, from, next) {
  if (localStorage.token) {
    next();
  } else {
    next('/login');
  }
}










  const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/auth/login.vue'),
    beforeEnter: loggedInRedirectDashboard,
  },



  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: isLoggedIn,
  },


 
  {
    path: '/menuList',
    name: 'menuList',
    component: () => import('../views/dataMaster/menuList.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/klpUsers',
    name: 'klpUsers',
    component: () => import('../views/dataMaster/klpUsers.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/registrasi',
    name: 'registrasi',
    component: () => import('../views/dataMaster/registrasi.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterPerkawinan',
    name: 'masterPerkawinan',
    component: () => import('../views/dataMaster/masterPerkawinan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterAgama',
    name: 'masterAgama',
    component: () => import('../views/dataMaster/masterAgama.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterBahasaAsing',
    name: 'masterBahasaAsing',
    component: () => import('../views/dataMaster/masterBahasaAsing.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterBesaranUpah',
    name: 'masterBesaranUpah',
    component: () => import('../views/dataMaster/masterBesaranUpah.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterPendidikan',
    name: 'masterPendidikan',
    component: () => import('../views/dataMaster/pendidikan/masterPendidikan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterJurusan',
    name: 'masterJurusan',
    component: () => import('../views/dataMaster/pendidikan/masterJurusan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterKeterampilan',
    name: 'masterKeterampilan',
    component: () => import('../views/dataMaster/pendidikan/masterKeterampilan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/masterBidang',
    name: 'masterBidang',
    component: () => import('../views/dataMaster/masterBidang.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/keterangan_umum',
    name: 'keterangan_umum',
    component: () => import('../views/biodata/keterangan_umum.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/bahasa_asing',
    name: 'bahasa_asing',
    component: () => import('../views/biodata/bahasa_asing.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/pendidikan_formal',
    name: 'pendidikan_formal',
    component: () => import('../views/biodata/pendidikan_formal.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/pengalaman_kerja',
    name: 'pengalaman_kerja',
    component: () => import('../views/biodata/pengalaman_kerja.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/jabatan',
    name: 'jabatan',
    component: () => import('../views/biodata/jabatan.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/lampiran',
    name: 'lampiran',
    component: () => import('../views/biodata/lampiran.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/verifikasiUsulan',
    name: 'verifikasiUsulan',
    component: () => import('../views/verifikasi/verifikasiUsulan.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/verifikasiUsulan_V2',
    name: 'verifikasiUsulan_V2',
    component: () => import('../views/verifikasi/verifikasiUsulan_V2.vue'),
    beforeEnter: isLoggedIn,
  },
  {
    path: '/verifikasiPerusahaan',
    name: 'verifikasiPerusahaan',
    component: () => import('../views/verifikasi/verifikasiPerusahaan.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/tracking',
    name: 'tracking',
    component: () => import('../views/tracking.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/cetak_kartu',
    name: 'cetak_kartu',
    component: () => import('../views/cetak_kartu.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/profil',
    name: 'profil',
    component: () => import('../views/perusahaan/profil.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/loker',
    name: 'loker',
    component: () => import('../views/perusahaan/loker.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/trackingPendaftar',
    name: 'trackingPendaftar',
    component: () => import('../views/perusahaan/trackingPendaftar.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/lowongan_kerja',
    name: 'lowongan_kerja',
    component: () => import('../views/lowongan_kerja.vue'),
    beforeEnter: isLoggedIn,
  },

  {
    path: '/berita',
    name: 'berita',
    component: () => import('../views/berita/berita.vue'),
    beforeEnter: isLoggedIn,
  },
  

  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    beforeEnter: isLoggedIn,
  },


]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
